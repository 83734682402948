<!--
 * @Description:用户端-首页-停车缴费-停车代缴 payForBehalf
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 11:49:06
 * @LastEditors: zhoucheng
-->
<template>
  <div class='main'>
    <van-nav-bar title="停车代缴"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack">
    </van-nav-bar>
    <div class="topBack"></div>
    <div class="main-content">
      <div class="content">
        <van-row class="titleTop">请输入完整的车牌号</van-row>
        <div class="platInput-box">
          <platInput v-model="platName"
                     :platLength="platLength"
                     @clickPlat="clickPlat"></platInput>
        </div>
        <van-row class="title">车牌颜色</van-row>
        <van-row class="plateColorDiv">
          <van-row class="plateColorItem"
                   v-for="(item,index) in plateColorList"
                   :key="index"
                   :style="item.code==plateColorCode?'border:1px solid #2FA4E5':''"
                   @click="handleSelect(item)">
            <van-row class="itemLeft"
                     :style="{'background':colorJson[item.name]}"></van-row>
            <span style="margin-left:5px">{{item.name}}</span>
          </van-row>
        </van-row>
      </div>
    </div>
    <div class="but-box">
      <van-button @click="handleAdd">确 认</van-button>
    </div>
    <platKeyboard v-model="popShow"
                  ref="keyBoard"
                  @keyClick="keyClick"
                  @clackDel="clackDel"
                  @close="handleclickClose"
                  @handleClickEmpty="handleClickEmpty"></platKeyboard>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import platInput from '@/components/platInput'
import platKeyboard from '@/components/platKeyboard'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    platInput,
    platKeyboard
  },
  data () {
    // 这里存放数据
    return {
      plateColorList: [], // 车牌颜色列表
      showPlateColorDialog: false, // 车牌颜色弹出窗
      plateColor: '蓝色', // 车牌颜色
      plateColorCode: 2, // 默认蓝色车牌编码
      platName: '渝', // 车牌号
      platLength: 8, // 车牌长度
      popShow: false, // 车牌键盘显示
      clickIdx: 1, // 点击后当前所在的index
      colorJson: {
        蓝色: '#5A6DF4 100%',
        绿色: '#009017 100%',
        黑色: '#262628 100%',
        黄色: '#F6C210 100%',
        白色: '#C8CAD8 100%'
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    platName () {
      if (this.platName.length === 8) {
        this.plateColorCode = 6
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  mounted () {
    this.getColorDict()
  },
  // 方法集合
  methods: {
    // 返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    },

    // 确认按钮
    handleAdd () {
      const info = {
        plateNumber: this.platName,
        numberPlateColorCode: this.plateColorCode
      }
      if (this.platName.length < 7) {
        this.$dialog.alert({ message: '请继续输入车牌' })
      } else if (this.platName.length === 7) {
        if (this.plateColorCode !== 6) {
          this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
            if (res.resultEntity) {
              this.$router.push({ name: 'parForParkCount', query: { plateNumber: this.platName, numberPlateColorCode: this.plateColorCode } })
            } else {
              this.$dialog.alert({
                message: '该车未停'
              })
              this.platName = '渝'
              this.plateColorCode = 2
              this.plateColor = '蓝色'
            }
          })
        } else {
          this.$dialog.alert({ message: '请选择正确的车牌颜色' })
        }
      } else if (this.platName.length === 8) {
        if (this.plateColorCode !== 6) {
          this.$dialog.alert({ message: '请选择正确的车牌颜色' })
        } else {
          const info = {
            plateNumber: this.platName
          }
          this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
            if (res.resultEntity) {
              this.$router.push({ name: 'parForParkCount', query: { plateNumber: this.platName } })
            } else {
              this.$dialog.alert({
                message: '该车未停'
              })
              this.platName = '渝'
              this.plateColorCode = 2
              this.plateColor = '蓝色'
            }
          })
        }
      }
    },
    // 选取车牌颜色
    handleSelect (item) {
      this.plateColor = item.name
      this.plateColorCode = item.code
      if (this.platName.length === 8 && this.plateColorCode !== 6) {
        this.platName = this.platName.substr(0, this.platName.length - 1)
      }
    },
    // 关闭车牌弹窗
    handleclickClose () {
      this.popShow = false
    },
    // 清空按钮
    handleClickEmpty () {
      this.platName = '渝'
      this.plateColorCode = 2
      this.plateColor = '蓝色'
    },
    // 键盘显示事件
    clickPlat (idx) {
      if (this.platName.length !== 1 && idx !== undefined) {
        this.clickIdx = idx
      }
      this.popShow = true
    },
    // 键盘点击事件
    keyClick (data) {
      if (this.clickIdx != null) {
        const arr = this.platName.split('')
        arr[this.clickIdx] = data
        // this.clickIdx = null
        this.clickIdx += 1
        this.platName = String(arr).replace(/,/g, '')
      } else {
        this.platName = this.platName + data
      }
      if (this.platName.length >= 1) {
        this.$refs.keyBoard.keyType = 1
      }
    },
    // 键盘删除按钮
    clackDel () {
      if (this.platName.length > 1) {
        const platNameList = this.platName.split('')
        platNameList.pop()
        this.platName = platNameList.join('')
        if (this.platName.length === 7 && this.platName.length < 8) {
          this.plateColorCode = 2
        }
      } else {
        this.platName = ''
      }
    },
    // 获取车牌颜色
    getColorDict () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2'
          }
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.plateColorList = response.resultEntity
      })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  width: 100%;
  height: 100%;
  position: relative;
  background: #f5f6f8 100%;
  .topBack {
    width: 100%;
    height: 180px;
    background-image: url(../../../../assets/plateNumber/background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
  }
  .main-content {
    width: 92%;
    height: 30%;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 4%;
    margin-top: 120px;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
      .titleTop {
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin-top: 6%;
        padding-left: 4.5%;
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #333333;
        letter-spacing: 0;
      }
      .title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        margin-top: 4%;
        padding-left: 4.5%;
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #666666;
        letter-spacing: 0;
      }
      .platInput-box {
        width: 95%;
        height: 55px;
        margin-top: 4%;
      }
      .plateColorDiv {
        width: 100%;
        height: 30px;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 10px;
        .plateColorItem {
          width: 17.5%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          margin-left: 1.5%;
          font-family: PingFangSC-Regular;
          font-size: 13px;
          color: #333333;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dbdbdb;
        }
        .itemLeft {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }
  }

  .but-box {
    width: 84%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8%;
    margin-top: 5%;
    .van-button {
      width: 100%;
      border-radius: 25px;
      background: #2fa4e5;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 3px;
    }
  }
}
</style>
